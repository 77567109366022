<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Create Bus Stop</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div
				v-if="loading"
				class="d-flex justify-content-center align-items-center"
				>
				<div class="spinner-border" role="status"></div>
			</div>
			<div class="row" v-else>
				<div class="col-12 col-xl-8">
					<div class="card">
						<div class="card-header">
							<div>
								<h4 class="card-header-title">Add a New Bus Stop</h4>
							</div>
						</div>
						<div class="card-body">
							<div
								class="alert alert-danger alert-dismissible fade show"
								role="alert"
								v-if="errorMessage"
								>
								{{ errorMessage }}
							</div>
							<form @submit.prevent="createRoute">
								<div class="row mt-4">
									<div class="col-12">
										<div class="form-group">
											<label class="form-label">Search Location</label>
											<google-map-autocomplete
												@place-changed="setLocation"
												:controls="false"
												class="px-4"
												:options="autocompleteOptions"
												></google-map-autocomplete>
										</div>
										<div class="form-group">
											<label for="description" class="col-form-label"
												>Description</label
												>
											<textarea
												v-model.trim="form.description"
												class="form-control"
												id="description"
												placeholder="Location description"
												required
												></textarea>
										</div>
									</div>
								</div>
								<button
									type="submit"
									class="btn btn-primary mt-4"
									:disabled="processing"
									>
									{{
										processing
											? 'Processing...'
											: "Create Bus Stop"
									}}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: ['userId'],
  data () {
    return {
      form: {
        location: null,
        description: ''
      },
      processing: false,
      errorProcessing: false,
      errorMessage: '',
      loading: false,
      errorLoading: false,
      autocompleteOptions: {
        bounds: {
          lat: 6.4627,
          lng: 3.3997
        },
        componentRestrictions: {
          country: 'ng'
        }
      }
    }
  },
  methods: {
    setLocation (location) {
      if (!location) {
        return
      }
      this.form.location = {
        address: location.formatted_address,
        latitude: location.geometry.location.lat(),
        longitude: location.geometry.location.lng()
      }
    },
    createRoute () {
      if (!this.form.location && !this.form.description) {
        return this.$swal({
          icon: 'error',
          title: 'Invalid data',
          text: 'Location and description are required, please review',
          showCloseButton: true
        })
      }
      this.processing = true
      this.errorProcessing = false
      this.errorMessage = ''
      this.axios
        .post('/v1/bus-stops', {
          location: this.form.location.address,
          location_description: this.form.description,
          latitude: this.form.location.latitude,
          longitude: this.form.location.longitude
        })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Bus stop Created',
            text: 'Bus Stop has been created successfully',
            showCloseButton: true
          }).then(() => {
            this.$router.push({
              name: 'BusStopsIndex'
            })
          })
        })
        .catch((e) => {
          this.errorProcessing = true
          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }
          this.errorMessage = msg
          this.$swal({
            icon: 'error',
            text: 'An error occured, please try again',
            showCloseButton: true
          })
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>

<style lang="css" scoped>
.pac-card {
  position: relative;
  box-shadow: none;
  background: none;
}
.pac-input-container,
.pac-input {
  width: 100% !important;
}
.pac-card .pac-input-container #pac-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263f !important;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #d2ddec;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
